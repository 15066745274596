import { configureStore } from '@reduxjs/toolkit';
import { UserApiCall } from './components/APISlices/userapiSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import allGamesSlice from './store/AllUsers';
import maxGamesPlayedReducer from './store/MaxCatagory';
import allWordsReducer from './store/AllWords';
import slideBarShowReducer from './store/SlideBarShow';
import weeklyMonthlyGamesReducer from './store/GetGameMonthData'

const store = configureStore({
  reducer: {
    [UserApiCall.reducerPath]:UserApiCall.reducer,
    slideBarShow: slideBarShowReducer,
    allGames: allGamesSlice,
    maxGameCatagory: maxGamesPlayedReducer,
    allWords: allWordsReducer,
    weeklyMonthlyGames: weeklyMonthlyGamesReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(UserApiCall.middleware),
});
setupListeners(store.dispatch)

export default store;
