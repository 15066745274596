/* eslint-disable */
import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <div className='container-lg zoundslike_copyright_webby_footer_synr'>
      <div>

        <span className=""> @2024 Copyright.
          Zoundslike®</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">Website by</span>
        <a href="https://syndelltech.com/" target="_blank" rel="noopener noreferrer">Syndell Inc.

        </a>
      </div>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
