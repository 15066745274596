// ***** logout functional component start*****//
import { deleteCookie } from 'cookies-next';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import '../Admin/Admin.css'
import { useNavigate } from 'react-router-dom';

const UserLogout=()=>{
  let navigate=useNavigate()
  useEffect(()=>{
    deleteCookie('Token')
    navigate('/login')
  },[])
}
  export default UserLogout
// ***** logout functional component end*****//