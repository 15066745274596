import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

export const fetchWeeklyGamesData = createAsyncThunk(
  'weeklyMonthlyGames/fetchWeeklyData', // Updated action type
  async () => {
    try {
      const response = await axios.get(baseURL + '/v1/game-month-week-wise-data');
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
);

export const fetchMonthlyGamesData = createAsyncThunk(
  'weeklyMonthlyGames/fetchMonthlyData', // Updated action type
  async () => {
    try {
      const response = await axios.get(baseURL + '/v1/game-year-week-wise-data');
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
);

export const fetchYearlyGamesData = createAsyncThunk(
  'yearlyGames/fetchMonthlyData', // Updated action type
  async () => {
    try {
      const response = await axios.get(baseURL + '/v1/game-year-wise-data');
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
);

const weeklyMonthlyGamesData = {
  weekly: null,
  monthly: null,
  yearly: null,
  loading: false,
  error: null,
};

export const weeklyMonthlyGamesSlice = createSlice({
  name: 'weeklyMonthlyGames',
  initialState: weeklyMonthlyGamesData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyGamesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWeeklyGamesData.fulfilled, (state, action) => {
        state.loading = false;
        state.weekly = action.payload;
      })
      .addCase(fetchWeeklyGamesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMonthlyGamesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMonthlyGamesData.fulfilled, (state, action) => {
        state.loading = false;
        state.monthly = action.payload;
      })
      .addCase(fetchMonthlyGamesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchYearlyGamesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchYearlyGamesData.fulfilled, (state, action) => {
        state.loading = false;
        state.yearly = action.payload;
      })
      .addCase(fetchYearlyGamesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { } = weeklyMonthlyGamesSlice.actions;

export default weeklyMonthlyGamesSlice.reducer;
