import React, { useEffect, useState } from 'react'
import Services from 'src/services/Services'
import './Voucher.css'
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CWidgetStatsA } from '@coreui/react';
import ZoundslikeCatagoryWithRemoveSvgIcon from './ZoundslikeCatagoryWithRemoveSvgIcon';
import ZoundslikeCatagoryWithAddSvgIcon from './ZoundslikeCatagoryWithAddSvgIcon';

function Voucher() {
    const [voucherSettingsData, setVoucherSettingsData] = useState(null)

    const [shouldEditIconVisible, setShouldEditIconVisible] = useState(false)
    const [mouseEnterOnEditSvg, setMouseEnterOnEditSvg] = useState(false)

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        handleDeleteComfirmationClick()
    }, [])

    const category = [
        {
            name: 'Soundalikes',
            value: 'category_soundalikes',
            color: '#ff45ed'
        },
        {
            name: 'Amounts',
            value: 'category_amounts',
            color: '#3e48ff'
        },
        {
            name: 'Anatomy',
            value: 'category_anatomy',
            color: '#00aaff'
        },
        {
            name: 'Animals',
            value: 'category_animals',
            color: '#39b70f'
        },
        {
            name: 'Architecture',
            value: 'category_architecture',
            color: '#ff4daf'
        },
        {
            name: 'Attire',
            value: 'category_attire',
            color: '#ff3e3e'
        },
        {
            name: 'Landscapes',
            value: 'category_landscapes',
            color: '#36ce36'
        },
        {
            name: 'Random',
            value: 'category_random',
            color: '#b20059'
        },
        {
            name: 'Dining',
            value: 'category_dining',
            color: '#efd203'
        },
        {
            name: 'Feelings',
            value: 'category_feelings',
            color: '#00baa3'
        },
        {
            name: 'Health',
            value: 'category_health',
            color: '#633eff'
        },
        {
            name: 'Home',
            value: 'category_home',
            color: '#ff5027',
            disabled: true
        },
        {
            name: 'Law',
            value: 'category_law',
            color: '#a9d100',
            disabled: true
        },
        {
            name: 'People',
            value: 'category_people',
            color: '#1569ff',
            disabled: true
        },
        {
            name: 'Sports',
            value: 'category_sports',
            color: '#ff6333',
            disabled: true
        },
        {
            name: 'Tech',
            value: 'category_tech',
            color: '#009fef',
            disabled: true
        },
        {
            name: 'Travel',
            value: 'category_travel',
            color: '#b331ff',
            disabled: true
        },
        {
            name: 'Work',
            value: 'category_work',
            color: '#ff9d00',
            disabled: true
        }
    ]

    category.sort((a, b) => {
        if (a.disabled && !b.disabled) return 1;
        if (!a.disabled && b.disabled) return -1;
      
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      

    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    const darkenColor = (hex) => {
        const multiplier = 0.8;
        const rgbColor = hexToRgb(hex);
        return rgbColor.replace(/(\d+)/g, (match) => Math.floor(match * multiplier));
    };

    const handleDeleteComfirmationClick = async () => {
        try {
            const data = await Services.getVoucherSettings('656ed30ac7744e6a96681d32');
            console.log(data);
            setVoucherSettingsData(data.data);
        } catch (error) {
            console.error('Error fetching voucher settings:', error);
        }
    }

    const handleRemoveCatagory = (data) => {
        const finalCatgoryName = 'category_' + data.toLowerCase();
        const updatedCategoryArray = voucherSettingsData.category.filter((cat) => cat !== finalCatgoryName)
        setVoucherSettingsData({
            ...voucherSettingsData,
            category: updatedCategoryArray,
        });
    }

    const handleAddCatagory = (new_catagory) => {
        const finalCatgoryName = 'category_' + new_catagory.toLowerCase();
        setVoucherSettingsData((prevSettings) => ({
            ...prevSettings,
            category: [...prevSettings.category, finalCatgoryName],
        }));
    }

    const handleTrueGameCountChange = (e) => {
        const numberOfTrueGame = e.target.value
        console.log('numberOfTrueGame', e.target.value);
        setVoucherSettingsData({
            ...voucherSettingsData,
            numberOfTrueGame: numberOfTrueGame,
        });
    }

    const handleWordsPlayGameCountChange = (e) => {
        const minimumWordsPlayed = e.target.value
        console.log('minimumWordsPlayed', e.target.value);
        setVoucherSettingsData({
            ...voucherSettingsData,
            minimumWordsPlayed: minimumWordsPlayed,
        });
    }

    const handleVoucherSettingSaveButtonClick = async () => {
        try {
            const data = await Services.UpdateVoucherSettings(voucherSettingsData);
            console.log(data);
            setVisible(false)
            // setVoucherSettingsData();
        } catch (error) {
            console.error('Error fetching voucher settings:', error);
        }
    }

    return (
        <div onMouseLeave={() => setShouldEditIconVisible(false)} onMouseEnter={() => setShouldEditIconVisible(true)} className="zoundslike_game_voucher_container">
            {/* ------------------------------------------------------------- modal code ---------------------------------------------------------------------- */}
            <CModal size="xl" alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                    <h4>Edit Settings</h4>
                </CModalHeader>
                {voucherSettingsData !== null && <div className='zoundslike_game_all_popup_voucher_setting_syn'>
                    <div className='zoundslike_two_conditions_container_syn'>
                        <div className='zoundslike_game_voucher_setting_syn'>
                            <p className='zoundslike_game_voucher_minimum_critarea_heading_syn'>Minimum True Game Should Be: </p>
                            <input min={0} onChange={handleTrueGameCountChange} type='number' className='zoundslike_game_voucher_minimum_critarea_input_syn' defaultValue={voucherSettingsData.numberOfTrueGame} />
                        </div>

                        <div className='zoundslike_game_voucher_setting_syn'>
                            <p className='zoundslike_game_voucher_minimum_critarea_heading_syn'>Minimum Words Played in Game Should Be:</p>
                            <input min={0} onChange={handleWordsPlayGameCountChange} type='number' className='zoundslike_game_voucher_minimum_critarea_input_syn' defaultValue={voucherSettingsData.minimumWordsPlayed} />
                        </div>
                    </div>

                    <div className='zoundslike_game_voucher_setting_for_category_included_syn'>
                        <h5 className='zoundslike_game_voucher_category_included_heading_syn'>Topic Included</h5>
                        <div className='zoundslike_game_voucher_included_catagorys_syn'>
                            {voucherSettingsData.category.map((categoryValue, index) => {
                                const matchingCategory = category.find(cat => cat.value === categoryValue);
                                const categoryName = matchingCategory ? matchingCategory.name : categoryValue;
                                const shadowColor = darkenColor(matchingCategory.color);
                                return (
                                    <ZoundslikeCatagoryWithRemoveSvgIcon handleRemoveCatagory={handleRemoveCatagory} key={index} categoryName={categoryName} backgroundColor={matchingCategory.color} shadowColor={shadowColor} />
                                );
                            })}
                        </div>
                    </div>

                    <div className='zoundslike_game_voucher_setting_for_category_included_syn'>
                        <h5 className='zoundslike_game_voucher_category_included_heading_syn'>Topic not Included</h5>
                        <div className='zoundslike_game_voucher_included_catagorys_syn'>
                            {category.map((cat, index) => {
                                if (!voucherSettingsData.category.includes(cat.value)) {
                                    const shadowColor = darkenColor(cat.color);

                                    var backgroundColor = cat.color
                                    if (cat.disabled === true) {
                                        backgroundColor = 'gray';
                                    } else {
                                        backgroundColor = cat.color
                                    }
                                    return (
                                        <ZoundslikeCatagoryWithAddSvgIcon handleAddCatagory={handleAddCatagory} key={index} categoryName={cat.name} backgroundColor={backgroundColor} shadowColor={shadowColor} />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>}
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
                    <CButton onClick={handleVoucherSettingSaveButtonClick} color="primary">Save</CButton>
                </CModalFooter>
            </CModal>
            {/* ------------------------------------------------------------- modal code ---------------------------------------------------------------------- */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 className='zoundslike_conditions_for_amazon_voucher_heading_syn'>Conditions for Amazon Voucher</h4>
                {shouldEditIconVisible && <div onClick={() => setVisible(true)} onMouseLeave={() => setMouseEnterOnEditSvg(false)} onMouseEnter={() => setMouseEnterOnEditSvg(true)}><svg xmlns="http://www.w3.org/2000/svg" width={mouseEnterOnEditSvg ? ("28") : ("25")} height={mouseEnterOnEditSvg ? ("28") : ("25")} fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                </svg></div>}
            </div>
            {voucherSettingsData !== null && <div className='zoundslike_game_all_voucher_setting_syn'>
                <div className='zoundslike_catagory_conditions_container_syn'>
                    <div className='zoundslike_game_voucher_setting_syn'>
                        <p className='zoundslike_game_voucher_minimum_critarea_heading_syn'>Minimum True Game Should Be: </p>
                        <p className='zoundslike_game_voucher_minimum_critarea_syn'>{voucherSettingsData.numberOfTrueGame}</p>
                    </div>

                    <div className='zoundslike_game_voucher_setting_syn'>
                        <p className='zoundslike_game_voucher_minimum_critarea_heading_syn'>Minimum Words Played in Game Should Be:</p>
                        <p className='zoundslike_game_voucher_minimum_critarea_syn'>{voucherSettingsData.minimumWordsPlayed}</p>
                    </div>
                </div>

                <div className='zoundslike_game_voucher_setting_for_category_included_syn'>
                    <h5 className='zoundslike_game_voucher_category_included_heading_syn'>Topic Included</h5>
                    <div className='zoundslike_game_voucher_included_catagorys_syn'>
                        {voucherSettingsData.category.map((categoryValue, index) => {
                            const matchingCategory = category.find(cat => cat.value === categoryValue);
                            const categoryName = matchingCategory ? matchingCategory.name : categoryValue;
                            const shadowColor = darkenColor(matchingCategory.color);
                            return (
                                <p style={{ background: matchingCategory.color, boxShadow: `rgb(${shadowColor}) 0px 3px 0px 0px` }} className='zoundslike_game_voucher_included_catagory_syn' key={index}>
                                    {categoryName}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default Voucher;