import React, { useEffect, useState } from 'react'
import "./login.css"
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import Services from '../../services/Services';
import { setCookie } from 'cookies-next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cibGmail } from '@coreui/icons';
import { useGetAllUsersQuery } from '../APISlices/userapiSlice';

const Login = () => {
    const [userdata, setData] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState([]);
    const [isAdmin, setIsAdmin] = useState([]);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [validAdmin, setValidAdmin] = useState([]);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [togglePassword, setTogglePassword] = useState(true)

    const { data, isLoading } = useGetAllUsersQuery()


    const handleSubmit = async (e) => {
        e.preventDefault();
        let person = {
            email: email,
            password: password
        }
        fetchUserData(person);
    };

    const fetchUserData = async (person) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValidEmail = emailRegex.test(person.email);
        if (isValidEmail) {
            const user = await Services.login(person);
            setData(user);
            if (user) {
                if (user.message) {
                    toast.error("Your email or password is incorrect.");
                } else {
                    console.log(user.user.role);
                    if (user.user.role !== 'admin') {
                        toast.error("Only administrators can log in.");
                    } else if (user.user.verification !== true) {
                        toast.error("Please complete the verification process.");
                    } else {
                        navigate("/dashboard")
                        setCookie("Token", user.token && user.token.accessToken)
                        console.log("JWT Token", user.token.accessToken);
                        const expiresInISO = user.token.expiresIn;
                        const tokenExpiryUnix = Math.floor(new Date(expiresInISO).getTime() / 1000);
                    }
                }
            }

        } else {
            toast.error("The email provided is not valid.");
        }

    };
   
    const handleTogglePassword = () => {
        setTogglePassword(!togglePassword)
    }

    return (
        <div className="zoundslike_login_container">
            <NotificationContainer />
            <ToastContainer />
            <form onSubmit={handleSubmit} className="zoundslike_login_div">
                <h3 className='zoundslike_login_login_heading'>Login</h3>
                <div className="zoundslike_input_group">
                    <div className='zoundslike_input_user_icon'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg></div>
                    <input onChange={(e) => setEmail(e.target.value)} type="text" className="zoundslike_user_input" id="candidateName" name="candidateName" placeholder=" " />
                    <label htmlFor="candidateName">Email</label>
                </div>

                <div className="zoundslike_input_group">
                    <div className='zoundslike_input_user_icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                        </svg>

                        {togglePassword ? (<svg style={{ zIndex: 1, opacity: 0.6 }} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>)
                            :
                            (<svg style={{ zIndex: 1, opacity: 0.6 }} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                            </svg>)}
                    </div>

                    <input onChange={(e) => setPassword(e.target.value)} type={togglePassword ? "password" : "text"} className="zoundslike_user_input" id="candidateName2" name="candidateName2" placeholder=" " />
                    <label htmlFor="candidateName2">Password</label>

                </div>

                <button type="submit" className="zoundslike_login_btn">
                    Login
                </button>
            </form>
            <NotificationContainer />
        </div>
    )
}

export default Login;