import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const GamesStackedBarChart = () => {
    const svgRef = useRef(null);

    useEffect(() => {
      // set the dimensions and margins of the graph
      const margin = { top: 10, right: 30, bottom: 20, left: 50 };
      const width = 460 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;
  
      // append the svg object to the body of the page
      const svg = d3.select(svgRef.current)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
  
      // Load data
      d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_stacked.csv").then(data => {
        // List of subgroups = header of the csv files = soil condition here
        const subgroups = data.columns.slice(1);
  
        // List of groups = species here = value of the first column called group -> I show them on the X axis
        const groups = data.map(d => d.group.toString()); // Parse group as string
  
        // Add X axis
        const x = d3.scaleBand()
          .domain(groups)
          .range([0, width])
          .padding([0.2]);
        svg.append("g")
          .attr("transform", `translate(0, ${height})`)
          .call(d3.axisBottom(x).tickSizeOuter(0));
  
        // Add Y axis
        const y = d3.scaleLinear()
          .domain([0, 60])
          .range([height, 0]);
        svg.append("g")
          .call(d3.axisLeft(y));
  
        // color palette = one color per subgroup
        const color = d3.scaleOrdinal()
          .domain(subgroups)
          .range(['#e41a1c', '#377eb8', '#4daf4a']);
  
        // stack the data? --> stack per subgroup
        const stackedData = d3.stack()
          .keys(subgroups)
          (data);
  
        // ----------------
        // Create a tooltip
        // ----------------
        const tooltip = d3.select(svgRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "1px")
          .style("border-radius", "5px")
          .style("padding", "10px");
  
        // Three functions that change the tooltip when user hover / move / leave a cell
        const mouseover = function (event, d) {
          const subgroupName = d3.select(this.parentNode).datum().key;
          const subgroupValue = d.data[subgroupName];
          tooltip
            .html(`Subgroup: ${subgroupName}<br>Value: ${subgroupValue}`)
            .style("opacity", 1);
        };
  
        const mousemove = function (event) {
          tooltip
            .style("left", (event.pageX + 10) + "px")
            .style("top", (event.pageY - 10) + "px");
        };
  
        const mouseleave = function () {
          tooltip
            .style("opacity", 0);
        };
  
        // Show the bars
        svg.append("g")
          .selectAll("g")
          // Enter in the stack data = loop key per key = group per group
          .data(stackedData)
          .enter().append("g")
          .attr("fill", d => color(d.key))
          .selectAll("rect")
          // enter a second time = loop subgroup per subgroup to add all rectangles
          .data(d => d)
          .enter().append("rect")
          .attr("x", d => x(d.data.group))
          .attr("y", d => y(d[1]))
          .attr("height", d => y(d[0]) - y(d[1]))
          .attr("width", x.bandwidth())
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);
      });
    }, []);
  
    return (
      <div ref={svgRef}></div>
    );
};

export default GamesStackedBarChart;

