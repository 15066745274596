import React, { useEffect, useRef, useCallback, useState } from 'react';
import './GamesBarChart.css'
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const OverallPlayedGamesBarChart = (props) => {
    const { data, filterSelectedValue } = props;
    const [dateCount, setDateCount] = useState({});
    const svgRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        setDateCount(data)
    }, [data])

    var x, xAxis, y, yAxis, svg;

    const margin = { top: 30, right: 30, bottom: 140, left: 140 },
        width = 1050 - margin.left - margin.right,
        height = 550 - margin.top - margin.bottom;

    const isEmpty = Object.keys(dateCount).length === 0;

    const update = useCallback((selectedVar) => {
        if (isEmpty === false) {
            const data = dateCount;
            x.domain(data.map(d => d.date));
            xAxis.transition().duration(1000).call(d3.axisBottom(x));

            y.domain([0, d3.max(data, d => +d[selectedVar])]);
            yAxis.transition().duration(1000).call(d3.axisLeft(y));

            const u = svg.selectAll("rect")
                .data(data);

            u.join("rect")
                .on("mouseover", function (event, d) {
                    const tooltip = tooltipRef.current;

                    const svgRect = svgRef.current.getBoundingClientRect();

                    const xPosition = event.clientX - svgRect.left;
                    const yPosition = event.clientY - svgRect.top;

                    tooltip.style.opacity = 1;
                    if (filterSelectedValue === 'Weekly') {
                        d.fullDate ? (tooltip.innerHTML = `${d.fullDate}<br>${selectedVar}: ${d[selectedVar]}`):(tooltip.innerHTML = `${d.date}<br>${selectedVar}: ${d[selectedVar]}`)
                    } else {
                        tooltip.innerHTML = `${d.date}<br>${selectedVar}: ${d[selectedVar]}`;
                    }
                    tooltip.style.left = `${xPosition}px`;
                    tooltip.style.top = `${yPosition}px`;
                })
                .on("mouseout", function () {
                    const tooltip = tooltipRef.current;
                    tooltip.style.opacity = 0;
                })
                .transition()
                .duration(1000)
                .attr("x", d => x(d.date))
                .attr("y", d => y(d[selectedVar]))
                .attr("width", x.bandwidth())
                .attr("height", d => height - y(d[selectedVar]))
                .attr("fill", "#4ba3c3");
        }
    }, [dateCount]);

    useEffect(() => {
        if (!isEmpty) {
            d3.select(svgRef.current).selectAll("*").remove();
            svg = d3.select(svgRef.current)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            x = d3.scaleBand()
                .range([0, width])
                .padding(0.2);
            xAxis = svg.append("g")
                .attr("transform", `translate(0,${height})`);

            y = d3.scaleLinear()
                .range([height, 0]);
            yAxis = svg.append("g")
                .attr("class", "myYaxis");

            update('count');
            // Rotate x-axis labels initially based on the number of bars
            const dataLength = data.length;
            if (dataLength > 45) {
                xAxis
                  .call(d3.axisBottom(x))
                  .selectAll('text')
                  .attr('transform', 'translate(-10,0)rotate(-60)')
                  .style('text-anchor', 'end')
                  .style('font-size', '12px');
            } else if (dataLength > 4) {
                xAxis.call(d3.axisBottom(x))
                    .selectAll("text")
                    .attr("transform", "translate(-10,0)rotate(-45)")
                    .style("text-anchor", "end");
            } else {
            xAxis
                .call(d3.axisBottom(x))
                .selectAll('text')
                .attr('transform', null)
                .style('text-anchor', 'middle')
            }
        }
    }, [update, dateCount]);

    return (
        <div className="chart-container">
            <div className="chart-wrapper">
                <svg
                    style={{marginTop: !filterSelectedValue && '45px'}}
                    ref={svgRef}
                    width="100%"
                    height={height - 100}
                    viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
                ></svg>
            </div>
            <div ref={tooltipRef} className="tooltip"></div>
        </div>
    );
};

OverallPlayedGamesBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    filterSelectedValue: PropTypes.string
};

export default OverallPlayedGamesBarChart;