import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

export const fetchMaxCatagoryGamePlayedData = createAsyncThunk(
    'yourApi/fetchAnotherData',
    async (data) => {
      try {
        const response = await axios.get(baseURL + '/v1/max-catagory-played-game', {
            params: data,
          });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

const gamesState = {
    gameData: null,
    loading: false,
    error: null,
  }
  
  export const maxGamesPlayedSlice = createSlice({
    name: 'games',
    initialState: gamesState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMaxCatagoryGamePlayedData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchMaxCatagoryGamePlayedData.fulfilled, (state, action) => {
          state.loading = false;
          state.gameData = action.payload;
        })
        .addCase(fetchMaxCatagoryGamePlayedData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  })
  
  export const { } = maxGamesPlayedSlice.actions
  
  export default maxGamesPlayedSlice.reducer